import { render, staticRenderFns } from "./SamplingPointsTableDisplay.vue?vue&type=template&id=450a791e&scoped=true&"
import script from "./SamplingPointsTableDisplay.vue?vue&type=script&lang=js&"
export * from "./SamplingPointsTableDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450a791e",
  null
  
)

export default component.exports