<template>
  <ToggleButtonGroup
    :toggle-buttons="toggleButtons"
    preselection
    font-weight="normal"
    @viewSelected="selectMapMode"
  />
</template>

<script>
import ToggleButtonGroup from '@components/ToggleButtonGroup'

export default {
  components: {
    ToggleButtonGroup
  },
  computed: {
    toggleButtons () {
      return [{
        title: this.$i18n.translate('Common.SR_Common.Point'),
        name: this.$i18n.translate('Common.SR_Common.Point'),
        icon: 'fa fa-plus',
        component: 'draw'
      },
      {
        title: this.$i18n.translate('Common.SR_Common.EditLabel'),
        name: this.$i18n.translate('Common.SR_Common.EditLabel'),
        icon: 'fa fa-edit',
        component: 'edit'
      }]
    }
  },
  methods: {
    selectMapMode (mode) {
      this.$emit('mode', mode)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
