<template lang="html">
  <div>
    <TextInput v-model="name" name="name" required>
      <template #label>
        <IxRes>phbb.samplingOrder.labels.orderName</IxRes>
      </template>
    </TextInput>

    <TextInput
      v-model="note"
      multiline
      name="note"
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.orderNote</IxRes>
      </template>
    </TextInput>

    <!-- required options -->
    <VueSelectize
      v-model="targetValue"
      :options="targetValueOptions.map(opt => opt.id)"
      :fields="targetValueFields"
      allow-empty
      required
      name="targetValue"
      :create="onCreate"
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.targetValue</IxRes>
      </template>
    </VueSelectize>

    <BoolSwitch v-model="entireField" name="entireField">
      <IxRes>phbb.create.samplingOrder.labels.entireField</IxRes>
    </BoolSwitch>
    <HelpBox>
      <IxRes>phbb.create.samplingOrder.descriptions.entireField</IxRes>
    </HelpBox>
    <br>

    <NumericInput
      v-model="numberOfPoints"
      required
      name="numbeOfPoints"
      :rule="min(1)"
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.numberOfPoints</IxRes>
      </template>
    </NumericInput>

    <hr>
    <CollapsibleSection v-model="mapSelectionShow">
      <HelpBox>
        <IxRes>phbb.create.samplingOrder.descriptions.selectedInterpolationMaps</IxRes>
      </HelpBox>
      <template #header>
        <IxRes>phbb.calibration.selectedInterpolationMaps</IxRes>
      </template>
      <InterpolationMapSelection v-model="selectedMapIds" :available-maps="availableMaps" />
    </CollapsibleSection>

    <!-- optional options -->
    <VueSelectize
      v-model="favoriteVariable"
      :options="favoriteVariableOptions"
      name="favoriteVariable"
      allow-empty
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.favoriteVariable</IxRes>
      </template>
    </VueSelectize>
    <HelpBox>
      <IxRes>phbb.create.samplingOrder.descriptions.favoriteVariable</IxRes>
    </HelpBox>

    <hr>

    <VueSelectize
      v-model="numberOfReferencePoints"
      :options="[1, 2]"
      name="numberOfReferencePoints"
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.minMaxPoints</IxRes>
      </template>
    </VueSelectize>
    <HelpBox>
      <IxRes>phbb.create.samplingOrder.descriptions.minMaxPoints</IxRes>
    </HelpBox>

    <NumericInput
      v-model="headlandBuffer"
      name="headlandBuffer"
      :rule="min(1)"
    >
      <template #label>
        <IxRes>phbb.create.samplingOrder.labels.buffer</IxRes>
      </template>
    </NumericInput>
    <HelpBox>
      <IxRes>phbb.create.samplingOrder.descriptions.buffer</IxRes>
    </HelpBox>
    <br>

    <HelpBox v-if="fieldStates.fieldStateMapSelected === 'error'" type="danger">
      <IxRes>phbb.calibration.select.map.nothingSelected</IxRes>
    </HelpBox>

    <IxButton
      large
      refresh
      @click="createSamplingPoints"
    >
      <IxRes>phbb.create.samplingOrder.buttons.createSamplingPoints</IxRes>
    </IxButton>
    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {mapFormFieldsFromValue} from '@helpers/vue/forms'

import {notifications} from 'src/js/infrastructure'

import CollapsibleSection from '@components/CollapsibleSection'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import VueSelectize from '@components/VueSelectize'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import BoolSwitch from 'src/vue/components/forms/BoolSwitch'
import InterpolationMapSelection from '../../widgets/InterpolationMapSelection'

import FormPartMixin from '@components/forms/FormPartMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    CollapsibleSection,
    TextInput,
    NumericInput,
    BoolSwitch,
    IxButton,
    HelpBox,
    VueSelectize,
    InterpolationMapSelection,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin
  ],
  props: {
    value: Object // order
  },
  data () {
    return {
      availableMaps: [],
      mapSelectionShow: false,
      targetValueOptions: [
        {
          id: 'soilTexture',
          name: this.$i18n.translate('phbb.samplingOrder.destinationSize_soilTexture')
        },
        {
          id: 'ph',
          name: this.$i18n.translate('phbb.samplingOrder.destinationSize_ph')
        },
        {
          id: 'humus',
          name: this.$i18n.translate('phbb.samplingOrder.destinationSize_humus')
        }
      ],
      sensorRastaData: [],
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      orgUnitId: state => state.navigation.location.orgUnitId,
      fieldId: state => state.navigation.location.fieldId,
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapFormFieldsFromValue([
      'name',
      'note',
      'targetValue',
      'selectedMapIds',
      'entireField',
      'numberOfPoints',
      'favoriteVariable',
      'numberOfReferencePoints',
      'headlandBuffer'
    ]),
    targetValueFields () {
      return {
        text: id => this.targetValueOptions.find(opt => opt.id === id).name,
        value: id => id
      }
    },
    favoriteVariableFields () {
      return {
        text: id => id,
        value: id => id
      }
    },
    favoriteVariableOptions () {
      return [...new Set(this.availableMaps.map(x => x.layerName))]
    }
  },
  methods: {
    async createSamplingPoints () {
      this.validate()
      this.validateMapSelection()

      await this.$nextTick()

      if (this.state !== 'success') {
        notifications.warning(this.$i18n.translate('phbb.samplingPointGeneration.notifications.inputs.validation'))
        return
      }

      try {
        this.loading = true

        const result = await smarterPost('/api/v2/phbb/orders/points/predict/{orgUnitId}/{fieldId}/{harvestYear}', this.value, {
          id: 'phbb.order.create-sampling-points',
          inputs: {
            harvestYear: () => this.harvestYear,
            orgUnitId: () => this.orgUnitId,
            fieldId: () => this.fieldId
          },
          onResult: result => {
            return result
          }
        })

        if (!result.success) {
          notifications.error(this.$i18n.translate('phbb.samplingPointGeneration.notifications.noResult'))
          console.error(result.rLog)
        } else {
          notifications.success(this.$i18n.translate('phbb.samplingPointGeneration.notifications.success'))
          this.$emit('pointsGenerated', result.points)
        }
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.samplingPointGeneration.notifications.error'))
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    validateMapSelection () {
      this.$set(this.fieldStates, 'fieldStateMapSelected', this.selectedMapIds.length > 0 ? 'success' : 'error')
    },
    onCreate (input, callback) {
      this.targetValueOptions.push({id: input, name: input})

      callback()

      this.$nextTick().then(() => {
        this.targetValue = input
      })
    }
  },
  watch: {
    selectedMapIds () {
      this.$set(this.fieldStates, 'fieldStateMapSelected', 'success')
    }
  },
  async mounted () {
    this.availableMaps = await smarterGet('/api/v2/map-management/fields/{fieldId}/interpolation-maps/{harvestYear}', {
      id: 'phbb.calibration.maps',
      expiry: 0,
      inputs: {
        fieldId: () => this.fieldId,
        harvestYear: () => this.harvestYear
      },
      onResult (maps) {
        return maps
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
