<template>
  <div>
    <SimpleTable
      :columns="columns"
      :data="features"
    >
      <template #sampleId="{value}">
        <span>{{ value }}</span>&nbsp;
        <a @click.prevent="startEditing(value)">
          <IxRes>Common.SR_Common.Edit</IxRes>
        </a>
      </template>
      <template #generated="{value}">
        <i :class="value ? 'fa fa-check' : 'fa fa-times'" />
      </template>
      <template #delete="{value}">
        <a href="#" @click.prevent="$emit('delete', value)">
          <IxRes>Common.SR_Common.Delete</IxRes>
        </a>
      </template>
    </SimpleTable>

    <BsModal v-model="showModal">
      <template #title>
        <IxRes>frs.baseFertilization.orderPlanning.headers.changePointLabel</IxRes>
      </template>

      <NumericInput v-model="newSampleId" integer>
        <template #label>
          <IxRes>frs.baseFertilization.orderPlanning.labels.newPointLabel</IxRes>
        </template>
      </NumericInput>

      <template #footer>
        <IxButton confirm @click="confirmModal">
          <IxRes>Common.SR_Common.Confirm</IxRes>
        </IxButton>
        <IxButton cancel @click="cancelModal">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </IxButton>
      </template>
    </BsModal>
  </div>
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'
import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import NumericInput from 'src/vue/components/forms/NumericInput'

export default {
  components: {
    SimpleTable,
    BsModal,
    IxButton,
    NumericInput
  },
  props: {
    features: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedSampleId: null,
      newSampleId: null,
      showModal: false
    }
  },
  computed: {
    columns () {
      return {
        sampleId: {title: this.$i18n.translate('phbb.samplingOrder.samplingPoints.id'), sortable: true, type: 'number'},
        generated: {title: this.$i18n.translate('phbb.samplingOrder.samplingPoints.generated'), sortable: false},
        delete: {
          title: '',
          value: x => x.sampleId
        }
      }
    }
  },
  methods: {
    startEditing (sampleId) {
      this.showModal = true
      this.selectedSampleId = sampleId
      this.newSampleId = sampleId
    },
    confirmModal () {
      this.showModal = false
      this.$emit('sampleIdChanged', {oldSampleId: this.selectedSampleId, newSampleId: this.newSampleId})
    },
    cancelModal () {
      this.showModal = false
      this.selectedSampleId = null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
