<template lang="html">
  <div class="order-creation-content">
    <Header target="orderAdministration">
      <template #back-title>
        <IxRes>phbb.buttons.backToOrderOverview</IxRes>
      </template>
      <template #title>
        <IxRes>
          phbb.samplingOrder.header.newOrder
        </IxRes>
      </template>
    </Header>

    <SamplingOrderCreationForm v-model="order" @pointsGenerated="onPointsGenerated" />

    <CollapsibleSection v-model="samplingPointsShow">
      <template #header>
        <IxRes>phbb.samplingOrder.headers.samplingPoints</IxRes>
      </template>
      <SamplingPointsTableDisplay :features="activeFeatures.map(x => x.properties)" @delete="onDeleteFeature" @sampleIdChanged="sampleIdChanged" />
    </CollapsibleSection>

    <MapModeSelection @mode="mapMode = $event" />

    <HelpBox v-if="duplicateSampleIdsFound" type="danger">
      <IxRes>phbb.samplingOrder.duplicateLabelsFound</IxRes>
    </HelpBox>

    <hr>
    <div>
      <IxButton large save @click="saveOrder">
        <IxRes>phbb.samplingOrder.labels.saveOrder</IxRes>
      </IxButton>
    </div>

    <IxVectorLayer
      :features="fieldBorderFeatures"
      :vector-style="styles.border"
      :z-index="1"
      layer-id="fields"
      auto-focus
    />
    <IxVectorLayer
      :features="activeFeatures"
      :vector-style="styles.point"
      :z-index="2"
      layer-id="activeFeatures"
    />
    <DrawInteraction
      v-if="mapMode === 'draw'"
      key="addPoints"
      type="Point"
      @finish="onDrawFinish"
    />
    <template v-if="mapMode === 'edit'">
      <EditInteraction v-model="activeFeatures" />
      <SnapInteraction :features="activeFeatures" />
    </template>
    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {difference, range} from 'lodash'

import {smarterPost} from '@helpers/vuex/data-loading'
import {mapState} from 'vuex'
import {planningPoint, fieldBorder} from '@frs/map-styles'
import {notifications} from 'src/js/infrastructure'
import {toWkt} from '@helpers/openlayers/features'

import DrawInteraction from '@components/map/interactions/DrawInteraction'
import EditInteraction from '@components/map/interactions/EditInteraction'
import SnapInteraction from '@components/map/interactions/SnapInteraction'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import HelpBox from '@components/help/HelpBox'
import CollapsibleSection from '@components/CollapsibleSection'
import IxVectorLayer from '@components/map/IxVectorLayer'
import IxButton from '@components/IxButton'

import MapModeSelection from './MapModeSelection'
import SamplingOrderCreationForm from './SamplingOrderCreationForm'
import SamplingPointsTableDisplay from './SamplingPointsTableDisplay'
import Header from '../../widgets/Header'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'
import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

export default {
  components: {
    DrawInteraction,
    EditInteraction,
    SnapInteraction,
    FrsLoadingIndicator,
    HelpBox,
    CollapsibleSection,
    IxVectorLayer,
    IxButton,
    MapModeSelection,
    SamplingOrderCreationForm,
    SamplingPointsTableDisplay,
    Header
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    DisableDefaultMapLayersMixin
  ],
  data () {
    return {
      loading: false,
      samplingPointsShow: false,
      mapMode: 'draw',
      editMode: false,
      order: {
        name: '',
        note: '',
        // required
        targetValue: null,
        entireField: true,
        numberOfPoints: 8,
        selectedMapIds: [],
        // optional
        favoriteVariable: null,
        numberOfReferencePoints: 1,
        headlandBuffer: 5
      },
      activeFeatures: [],
      generatedFeatures: []
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', {
      simplifiedGeometries: state => state.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem', {
      orgUnitId: state => state.navigation.location.orgUnitId,
      fieldId: state => state.navigation.location.fieldId,
      harvestYear: state => state.userSettings.harvestYear
    }),
    styles () {
      return {
        point: planningPoint,
        border: fieldBorder
      }
    },
    fieldGeometry () {
      return this.simplifiedGeometries[this.fieldId]
    },
    fieldBorderFeatures () {
      return [{
        type: 'Feature',
        geometry: this.fieldGeometry,
        properties: {id: this.fieldId}
      }]
    },
    nextSampleId () {
      return difference(range(1, 1000), this.activeFeatures.map(x => x.properties.sampleId)).shift()
    },
    duplicateSampleIdsFound () {
      const set = new Set(this.activeFeatures.map(x => x.properties.sampleId))
      return set.size !== this.activeFeatures.length
    }
  },
  methods: {
    onPointsGenerated (collection) {
      this.removeFeaturesFromPreviousRun()
      collection.features.forEach(feature => {
        feature.properties = {...feature.properties, ...{sampleId: this.nextSampleId, generated: true}}
        this.activeFeatures.push(feature)
      })
      this.generatedFeatures = collection.features
    },
    removeFeaturesFromPreviousRun () {
      this.generatedFeatures.forEach(generated => {
        const index = this.activeFeatures.findIndex(active => active.properties.sampleId === generated.properties.sampleId)
        if (index !== -1) {
          this.activeFeatures.splice(index, 1)
        }
      })
      this.generatedFeatures = []
    },
    onDrawFinish (feature) {
      feature.properties = {generated: false, sampleId: this.nextSampleId}
      this.activeFeatures.push(feature)
    },
    onDeleteFeature (sampleId) {
      const index = this.activeFeatures.findIndex(active => active.properties.sampleId === sampleId)
      if (index !== -1) {
        this.activeFeatures.splice(index, 1)
      }
    },
    async saveOrder () {
      try {

        // validate required fields manually, because FormPartMixin is already used within SamplingOrderCreationForm
        if (this.activeFeatures.length === 0) {
          notifications.error(this.$i18n.translate('phbb.samplingOrder.create.notifications.activeFeatures.error'))
          return
        }

        if (!this.order.name || !this.order.targetValue || this.order.numberOfPoints === 0 || !this.order.numberOfPoints) {
          notifications.error(this.$i18n.translate('phbb.samplingOrder.create.notifications.requiredFields.error'))
          return
        }

        this.loading = true

        const {name, note, targetValue, entireField, numberOfPoints, selectedMapIds, favoriteVariable, numberOfReferencePoints, headlandBuffer} = this.order

        const data = {
          name,
          note,
          targetValue,
          entireField,
          numberOfPoints,
          selectedMapIds,
          favoriteVariable,
          numberOfReferencePoints,
          headlandBuffer
        }
        data.probingPoints = this.activeFeatures.map(feature => ({sampleId: feature.properties.sampleId, geometry: toWkt(feature)}))

        await smarterPost('/api/v2/phbb/orders/{orgUnitId}/{fieldId}/{harvestYear}', data, {
          id: 'phbb.order.create',
          inputs: {
            harvestYear: () => this.harvestYear,
            orgUnitId: () => this.orgUnitId,
            fieldId: () => this.fieldId
          },
          onResult: result => {
            return result
          }
        })
        notifications.success(this.$i18n.translate('phbb.samplingOrder.create.notifications.success'))

        this.$router.push({name: 'orderAdministration'})
      } catch {
        notifications.error(this.$i18n.translate('phbb.samplingOrder.create.notifications.error'))
      } finally {
        this.loading = false
      }
    },
    sampleIdChanged ({oldSampleId, newSampleId}) {
      const feature = this.activeFeatures.find(x => x.properties.sampleId === oldSampleId)
      feature.properties.sampleId = newSampleId
    }
  }
}
</script>

<style lang="scss" scoped>
.order-creation-content {
  display: flex;
  flex-direction: column;

  margin: 5px;
}
</style>
